import React from "react"
import {Link} from "react-router-dom";
// MUI
import {
    Box,
    Divider,
    Grid,
    IconButton,
    Paper,
    Tooltip,
    Typography, Alert, Stack, Button
} from "@mui/material";
import {Check, Close, Delete, Download, Edit, InfoRounded, Visibility} from "@mui/icons-material";
// Primereact
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
// Components
import ContactView from "./ContactView";
import {DeleteContactDialog} from "../../Dialogs";
import {Paginator, Loading} from "../../utils";
// Translation
import {useTranslation} from "react-i18next";
// API
import {useLazyQuery, useMutation} from "@apollo/client";
import {APIPostBlob, DELETE_CONTACT, GET_CONTACTS, GET_VCARDS} from "../../../api/calls";
// Misc
import {iso8601ToHumanString} from "../../../functions/functions";
// Types
import {ContactTypes, VcardTypes} from "../../../types";
// Hooks
import {useUser} from "../../../provider/UserProvider";

const {REACT_APP_DASHBOARD_API_URL} = process.env


export default function ContactsPanel() {

    const {t} = useTranslation(["contactEditor", "common"])
    const {token} = useUser()
    // Contacts state
    const [contacts, setContacts] = React.useState<ContactTypes.ConactList[]>([])
    const [vcards, setVcards] = React.useState<VcardTypes.Vcard[]>([])
    // Dialog states
    const [viewing, setViewing] = React.useState({ open: false, id: "", vcardUuid: "" })
    const [deleting, setDeleting] = React.useState({ open: false, id: "" })
    // Filters
    const [pageNumber, setPageNumber] = React.useState<number>(1)
    const [perPage, setPerPage] = React.useState<number>(10)
    const [isLastPage, setIsLastPage] = React.useState<boolean>(false)

    const [getContactsList, {
        data: contactsData,
        loading: contactsLoading,
        error: contactsError
    }] = useLazyQuery(GET_CONTACTS, { fetchPolicy: "no-cache" })

    const [getVcards, {
        data: vcardsData,
        loading: vcardsLoading,
        error: vcardsError
    }] = useLazyQuery(GET_VCARDS, { fetchPolicy: "no-cache" })

    const [deleteContact, {
        loading: deletionLoading,
        error: deletionError
    }] = useMutation(DELETE_CONTACT, { fetchPolicy: "no-cache" })

    React.useEffect(() => {
        getContactsList({ variables: { page: 1, perPage: 100}}).then()
        getVcards(({ variables: { page: 1, perPage: 100}})).then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (contactsData && contacts !== contactsData) {
            setContacts(contactsData.getContacts.values)
        }

        if (vcardsData && vcards !== vcardsData) {
            setVcards(vcardsData.getVcards.values)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactsData, vcardsData])

    React.useEffect(() => {
        if (contactsData) {
            let contacts = pageNumber * perPage
            if ((contacts >= contactsData.getContacts.total) || contactsData.getContacts.total === undefined) {
                setIsLastPage(true)
            } else {
                setIsLastPage(false)
            }
        }

    }, [pageNumber, contactsData, perPage])

    // handlers
    const handleDeleteContact = () => {
        deleteContact({ variables: {
            contactUuids: [deleting.id]
        }}).then(() => {
            if (deletionError) { return }
            setDeleting({open: false, id: ""})
            getContactsList({ variables: { page: 1, perPage: 100}}).then()
        })
    }

    // specific calls
    const downloadContactsSpreadsheet = async () => {

        await APIPostBlob<any>(REACT_APP_DASHBOARD_API_URL + "/vcards/contacts", null, token).then((data: any) => {
            if (data.ok) {
                console.log(data)
                if (data.rawBlob !== undefined) {
                    let url: string = window.URL.createObjectURL(data.rawBlob);
                    let a: HTMLAnchorElement = document.createElement('a');
                    a.href = url;
                    a.download = "vCard-Pro-Contacts.csv" ;
                    a.click();
                    URL.revokeObjectURL(a.href);
                }
            }
        })
    }

    // Data table
    const dataTableNameBody = (rowData: any) => {
        return (
            <>
                <Tooltip title={rowData.completed ? t("contact_completed") : t("contact_uncomplete")} placement={"top"} arrow>
                    {
                        rowData.completed ? <Check color={"primary"} sx={{ fontSize: 12 }} />
                            : <Close color={"error"} sx={{ fontSize: 12 }} />
                    }
                </Tooltip>
                &nbsp; {rowData.information.firstName}&nbsp;{rowData.information.lastName}
            </>
        )
    }

    const dataTableDateBody = (rowData: any) => {
        return iso8601ToHumanString(rowData.createdAt)
    }

    const dataTableVcardBody = (rowData: any) => {
        if (!vcards) return <></>

        let holder = vcards.filter((vcard: any) => {
            return vcard.uuid === rowData.vcardUuid
        })

        if (holder && holder.length > 0) {
            return <>{holder[0].user.firstName}&nbsp;{holder[0].user.lastName}</>
        }

        return (
            <>
                <Typography variant={"body2"} color={"error"}>
                    <Tooltip title={t("contact_vcard_not_found_text")} placement={"top"} arrow>
                        <InfoRounded color={"error"} sx={{ fontSize: 14, mb: "-2px" }} />
                    </Tooltip>
                    &nbsp;
                    {t("contact_vcard_not_found")}
                </Typography>
            </>
        )
    }

    const dataTableActionBody = (rowData: any) => {
        return (
            <Grid container justifyContent={"flex-start"} spacing={1}>
                <Grid item>
                    <Tooltip title={t("view")} placement={"top"} arrow>
                        <IconButton size={"small"} onClick={() => setViewing({ open: true, id: rowData.uuid, vcardUuid: rowData.vcardUuid })}>
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title={t("edit")} placement={"top"} arrow>
                        <IconButton size={"small"} component={Link} to={`/contact/edit?id=${rowData.uuid}`}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title={t("delete")} placement={"top"} arrow>
                        <IconButton size={"small"} onClick={() => setDeleting({ open: true, id: rowData.uuid })}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            )
    }

    const returnContactVcardHolder = (uuid: any) => {
        if (!vcards || uuid === "" || !uuid) return ""

        if (vcards && vcards.length > 0) {
            let holder = vcards.filter((vcard: any) => {
                return vcard.uuid === uuid
            })

            if (holder && holder.length > 0 && holder[0].user.firstName) {
                return `${holder[0].user.firstName} ${holder[0].user.lastName}`
            }
        }

        return ""
    }

    return (
        <>
            <Box p={2}>
                <Grid container justifyContent={{ xs: "center" }} rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"h4"} component={"p"}>
                            {t("contacts_list")}
                        </Typography>
                        <Typography>
                            {t("contacts_list_intro")}
                        </Typography>
                    </Grid>

                    <Grid item xs={"auto"}>
                        {/* TODO: add documentation link */}
                        {/*<Button*/}
                        {/*    variant={"outlined"}*/}
                        {/*    disableElevation*/}
                        {/*    component={"a"}*/}
                        {/*    href={""}*/}
                        {/*>*/}
                        {/*    {t("contacts_documentation")}*/}
                        {/*</Button>*/}
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    {
                        (vcardsError || contactsError) &&
                        <Grid item xs={12}>
                            <Alert severity={"error"}>
                                {t("contact_panel_loading_error")}
                            </Alert>
                        </Grid>
                    }

                    {
                        (contactsLoading || vcardsLoading) ?
                            <Grid item xs={12}>
                                <Loading />
                            </Grid>
                            :
                            <>
                                <Grid item xs={12}>
                                    <Stack direction={"row"} spacing={2}>
                                        <Button
                                            startIcon={<Download />}
                                            variant={"contained"} disableElevation
                                            onClick={() => downloadContactsSpreadsheet()}
                                        >
                                            {t("download_contacts")}
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} className={"contactsListDataTable"}>
                                    <Paper variant={"outlined"}>
                                        <DataTable
                                            value={contacts}
                                            breakpoint={"1103px"}
                                            emptyMessage={t("contacts_list_empty_message")}
                                            rows={10}
                                            rowsPerPageOptions={[10,20,50]}
                                        >
                                            <Column header={t("name")} body={dataTableNameBody} />
                                            <Column header={t("contact_date")} body={dataTableDateBody} />
                                            <Column header={t("phone")} field={"information.phone"} />
                                            <Column header={t("contact_user")} body={dataTableVcardBody} />
                                            <Column header={t("actions")} body={dataTableActionBody} />
                                        </DataTable>
                                    </Paper>
                                </Grid>

                                <Paginator {...{ perPage, setPerPage, pageNumber, setPageNumber, isLastPage, t}} />
                            </>
                    }
                </Grid>



            </Box>

            <ContactView
                open={viewing.open}
                contactId={viewing.id}
                onClose={() => setViewing({ open: false, id: "", vcardUuid: "" })}
                vcardHolder={returnContactVcardHolder(viewing.vcardUuid)}
                t={t}
            />

            <DeleteContactDialog
                open={deleting.open}
                onClose={() => setDeleting({ open: false, id: ""})}
                onDelete={() => handleDeleteContact()}
                loading={deletionLoading}
                t={t}
            />
        </>
    )
}