import {gql} from "@apollo/client";

const GET_TEMPLATES = gql`
    query getTemplates($page: Int!, $perPage: Int!) {
        getTemplates(page: $page, perPage: $perPage) {
            total
            values {
                uuid
                name
                base
                coverPicture
                templateType
                customisation {
                    backgroundExt {
                        backgroundColor
                        isGradient
                        gradientColor1
                        gradientColor2
                    }
                    backgroundInt {
                        backgroundColor
                    }
                    fontNameHolder {
                        fontName
                        fontColor
                    }
                    fontCardTitles {
                        fontName
                        fontColor
                    }
                    fontCardTexts {
                        fontName
                        fontColor
                    }
                    templateOption {
                        angles
                        anglesColor
                    }
                    buttons {
                        font {
                            fontName
                            fontColor
                        }
                        backgroundColor
                    }
                }
            }
        }
    }
`

const GET_TEMPLATE = gql`
    query getTemplate($templateUuid: UUID!) {
        getTemplate(templateUuid: $templateUuid) {
            name
            base
            qrcodeTemplateUuid
            qrcodeDomainUuid
            templateType
            customisation {
                backgroundExt {
                    backgroundColor
                    isGradient
                    gradientColor1
                    gradientColor2
                }
                backgroundInt {
                    backgroundColor
                    isGradient
                    gradientColor1
                    gradientColor2
                }
                fontNameHolder {
                    fontName
                    fontColor
                }
                fontCardTitles {
                    fontName
                    fontColor
                }
                fontCardTexts {
                    fontName
                    fontColor
                }
                buttons {
                    font {
                        fontName
                        fontColor
                    }
                    backgroundColor
                    backgroundOverColor
                    textOverColor
                    displaySendContactButton
                    displayAddMe
                    displayWallet
                    displayFloatingAddMe
                }
                icons {
                    socialNetworkColor
                    sectionColor
                    actionIconColor
                    actionIconOverColor
                }
                templateOption {
                    angles
                    anglesColor
                    shadow
                    shadowColor
                    displayProfilePicture
                }
                applePassBackgroundColor
                applePassForegroundColor
                googlePassBackgroundColor
                googlePassFavIcon
                separator
            }
            companyLogo
            coverPicture
            information {
                company {
                    name
                    address
                    address2
                    postalCode
                    city
                    state
                    country
                    phone
                    website
                    email
                }
                office {
                    address
                    address2
                    postCode
                    city
                    state
                    country
                    email
                    phone
                    website
                }
                sections {
                    name
                    profiles {
                        firstName
                        lastName
                        title
                        jobTitle
                        phone
                        email
                        link
                        description
                        closingText
                        address
                        website
                    }
                }
                medias {
                    youtubeUrl
                    custom {
                        title
                        fileName
                        customUrl
                        mediaGroupName
                    }
                }
                socialNetworks {
                    name 
                    externalUrl
                }
            }
        }
    }
`

const CREATE_TEMPLATE = gql`
    mutation createTemplate($newTemplate: TemplateInput!) {
        createTemplate(newTemplate: $newTemplate) {
            name
            uuid
        }
    }
`

const DELETE_TEMPLATE = gql`
    mutation deleteTemplates($templates_uuids: [UUID!]!) {
        vcard: deleteTemplates(templateUuids: $templates_uuids)
}
`

const MODIFY_TEMPLATE = gql`
    mutation modifyTemplate($template: ModifyTemplateInput!) {
        modifyTemplate(template: $template) {
            uuid            
        }
    }
`

// VCARD
const CREATE_VCARD = gql`
    mutation createVCard($newVCard: VCardInput!) {
        createVCard(newVCard: $newVCard) {
            uuid
            url
        }
    }
        
`

const GET_VCARD = gql`
    query getVcard($vcardUuid: UUID!) {
        getVcard(vcardUuid: $vcardUuid) {
            uuid
            templateUuid
            ownerUuid
            qrcodeUrl
            departmentUuid
            user {
                active
                title
                lastName
                firstName
                jobTitle
                jobTitleAdditional
                businessEntity
                picture
                email
                departmentUuid
                departmentHeadUuid
                information {
                    contact {
                        phone
                        secondaryPhone
                        other {
                            name
                            id
                        }
                    }
                    biography
                    medias {
                        youtubeUrl
                        custom {
                            title
                            fileName
                            customUrl
                            mediaGroupName
                        }
                    }
                    office {
                        address
                        address2
                        postCode
                        city
                        state
                        country
                        email
                        phone
                        website
                    }
                    sections {
                        name
                        profiles {
                            firstName
                            lastName
                            title
                            jobTitle
                            phone
                            email
                            link
                            description
                            closingText
                            address
                            website
                        }
                    }
                    socialNetworks {
                        name
                        externalUrl
                    }
                }
            }
        }
    }
`

const GET_VCARDS = gql`
    query getVcards($page: Int, $perPage: Int, $filter: Filter) {
        getVcards(page: $page, perPage: $perPage, filter: $filter) {
            total
            values {
                uuid
                url
                qrcodePreviewUrl
                qrcodeUrl
                templateUuid
                departmentUuid
                ownerUuid
                user {
                    departmentUuid
                    permission {
                        ownerUuid
                        companyUuid
                        Role
                    }
                    title
                    firstName
                    lastName
                    picture
                    email
                }
            }
        }
    }
`

const DELETE_VCARD = gql`
    mutation deleteVCards($vcardUuids: [UUID!]!, $newContactHolder: String!) {
        deleteVCards(vcardUuids: $vcardUuids, newContactHolder: $newContactHolder)
    }
`

const MODIFY_VCARD = gql`
    mutation modifyVCard($vcard: ModifyVCardInput!) {
        modifyVCard(vcard: $vcard) {
            uuid
        }
    }
`

// CONTACTS
const CREATE_CONTACT = gql`
    mutation createContact($newContact: ContactInput!) {
        createContact(newContact: $newContact) {
            information {
                lastName
                firstName
            }
        }
    }
`

const GET_CONTACTS = gql`
    query getContacts($page: Int!, $perPage: Int!) {
        getContacts(page: $page, perPage: $perPage) {
            total
            values {
                uuid
                vcardUuid
                completed
                createdAt
                updatedAt
                information {
                    lastName
                    firstName
                    phone
                    email
                    allowCommercialEmails
                    commentSection
                    companyName
                    companyAddress
                    companyAddress2
                    companyCity
                    companyPostalCode
                    companyCountry
                    companyState
                }
            }
        }
    }
`

const GET_CONTACT = gql`
    query getContact($contactUuid: UUID!) {
        getContact(contactUuid: $contactUuid) {
            uuid
            vcardUuid
            completed
            createdAt
            updatedAt
            information {
                lastName
                firstName
                phone
                email
                allowCommercialEmails
                commentSection
                companyName
                companyAddress
                companyAddress2
                companyCity
                companyPostalCode
                companyCountry
                companyState
            }
        }
    }
`

const DELETE_CONTACT = gql`
    mutation deleteContacts($contactUuids: [UUID!]!) {
        deleteContacts(contactUuids: $contactUuids)
    }
`

const MODIFY_CONTACT = gql`
    mutation modifyContact($contact: ModifyContactInput!) {
        modifyContact(contact: $contact) {
            uuid
        }
    }
`

// COMPANY
const GET_COMPANY = gql`
    query getCompany {
        getCompany {
            name
            createdAt
            updatedAt
            options {
                contactFormat
                contactFormComment
                permissions {
                    name
                    position
                    photo
                    telephone
                    customContact
                    biography
                    youtube
                    media
                    office
                    customSection
                    socialNetwork
                }
            }
        }
    }
`

const MODIFY_COMPANY = gql`
    mutation modifyCompany($company: ModifyCompanyInput!) {
        modifyCompany(company: $company) {
            name
            options {
                contactFormat
                contactFormComment
            }
        }
    }
`

// IMAGE UPLOAD
// const UPLOAD_IMAGE = gql`
//     mutation uploadImage($file: Upload!) {
//         uploadImage(file: $file)
//     }
// `

// DEPARTMENTS
const CREATE_DEPARTMENT = gql`
    mutation createDepartment ($newDepartment: DepartmentInput!) {
        createDepartment (newDepartment: $newDepartment) {
            name
        }
    }
`

const MODIFY_DEPARTMENT = gql`
    mutation modifyDepartment ($department: ModifyDepartmentInput!) {
        modifyDepartment (department: $department) {
            name
        }
    }
`

const GET_DEPARTMENTS = gql`
    query getDepartments ($page: Int, $perPage: Int, $filter: Filter) {
        getDepartments (page: $page, perPage: $perPage, filter: $filter) {
            total
            values {
                uuid
                name
                vcards {
                    uuid
                }
            }
        }
    }
`

// const GET_DEPARTMENT = gql`
//     query getDepartment ($departmentUuid: UUID!) {
//         getDepartment (departmentUuid: $departmentUuid) {
//             name
//             uuid
//         }
//     }
// `

const DELETE_DEPARTMENTS = gql`
    mutation deleteDepartments ($departmentUuids: [UUID!]!) {
        deleteDepartments (departmentUuids: $departmentUuids)
    }
`

const ADD_VCARD_TO_DEPARTMENT = gql`
    mutation addVCardToDepartement ($departementUuid: UUID!, $newVcardUuids: [UUID!]!) {
        modifyDepartmentVCards(departmentUuid: $departementUuid, newVcardUuids:  $newVcardUuids) {
            uuid
        }
    }
`

const REMOVE_VCARD_FROM__DEPARTMENT = gql`
    mutation addVCardToDepartement ($departementUuid: UUID!, $newVcardUuids: [UUID!]!) {
        modifyDepartmentVCards(departmentUuid: $departementUuid, delVcardUuids: $newVcardUuids) {
            uuid
        }
    }
`

// //////////////////////
// //////////////////////
// //////////////////////
// //////////////////////
// //////////////////////
// VANILLA API CALLS
// //////////////////////
// //////////////////////
// //////////////////////
// //////////////////////
// //////////////////////

interface HttpResponse<T> extends Response {
    parsedBody?: T;
    rawBlob?: Blob;
}

async function httpAPI<T>(request: RequestInfo):Promise<HttpResponse<T>> {

    const response: HttpResponse<T> = await fetch(request, {
        credentials: 'include'
    });

    try {
        response.parsedBody = await response.json();
    } catch (e) {
        throw new Error(response.statusText);
    }
    return response;
}

async function httpBlob<T>(request: RequestInfo): Promise<HttpResponse<T>> {

    const response: HttpResponse<T> = await fetch(request, {
        credentials: "include"
    });

    try {
        response.rawBlob = await response.blob()
    } catch (e) {
        throw new Error(response.statusText);
    }
    return response;
}

async function APIPostFile<T>(
    path: string,
    file: any,
    token: any,
    args = {
        method: 'post',
    }
): Promise<HttpResponse<T>>  {

    const data = new FormData();
    data.append('File', file);

    const req: RequestInit = {
        method: args.method,
        body: data,
        headers: [
            ['Authorization', token],
        ]
    }

    return await httpAPI<T>(new Request(path, req));
}

async function APIPost<T>(
    path: string,
    body: any,
    token?: any,
    args = {
        method: 'post',
        body: JSON.stringify(body),
    }
): Promise<HttpResponse<T>> {

    const req: RequestInit = {
        method: args.method,
        body: args.body,
        headers: [
            ['Authorization', token],
        ]
    }
    return await httpAPI<T>(new Request(path, req));
}

async function APIPostBlob<T>(
    path: string,
    body: any,
    token: any,
    args = {
        method: 'post',
        body: JSON.stringify(body),
    }
): Promise<HttpResponse<T>> {

    const req: RequestInit = {
        method: args.method,
        body: args.body,
        headers: [
            ['Authorization', token],
        ]
    }

    return await httpBlob<T>(new Request(path, req));
}


async function APIGet<T>(path: string, token: any, args =
    { method: 'get' }): Promise<HttpResponse<T>> {

    const req: RequestInit = {
        method: args.method,
        headers: [
            ['Authorization', token],
        ]
    }

    return await httpAPI<T>(new Request(path, req));
}

export {
    GET_TEMPLATES,
    CREATE_TEMPLATE,
    GET_TEMPLATE,
    DELETE_TEMPLATE,
    MODIFY_TEMPLATE,

    CREATE_VCARD,
    GET_VCARDS,
    MODIFY_VCARD,
    GET_VCARD,
    DELETE_VCARD,

    CREATE_CONTACT,
    GET_CONTACTS,
    GET_CONTACT,
    DELETE_CONTACT,
    MODIFY_CONTACT,

    GET_COMPANY,
    MODIFY_COMPANY,

    // UPLOAD_IMAGE,

    CREATE_DEPARTMENT,
    MODIFY_DEPARTMENT,
    // GET_DEPARTMENT,
    GET_DEPARTMENTS,
    DELETE_DEPARTMENTS,
    ADD_VCARD_TO_DEPARTMENT,
    REMOVE_VCARD_FROM__DEPARTMENT,

    // GET_FULL_VCARD,

    APIGet,
    APIPostFile,
    APIPost,
    APIPostBlob
}